import React, { Component } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { WithTranslation } from 'next-i18next';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { DownArrow, UpArrow } from '../../common/Slider';
import { EventLayer } from '../../../domain/editorial';

import { EventsMap } from './EventsMap';
import { SentenceAnimation } from './index';

library.add(faArrowRight);

export type InteractiveMapProps = EventLayer & WithTranslation;

interface InteractiveMapState {
    hoveredID: string;
}

interface Location {
    name: string;
    code: string;
}
interface Pin {
    startingAt: string;
    endingAt?: string;
    location: Location;
    title: string;
    subTitle: string;
    url?: string;
}

export class InteractiveMap extends Component<InteractiveMapProps, InteractiveMapState> {
    public constructor(props: InteractiveMapProps) {
        super(props);

        this.state = {
            hoveredID: '',
        };

        this.setHoveredID = this.setHoveredID.bind(this);
        this.unsetHoveredID = this.unsetHoveredID.bind(this);
        this.renderEventSlide = this.renderEventSlide.bind(this);
    }

    private setHoveredID(hoveredID: string): void {
        this.setState({
            hoveredID,
        });
    }

    private unsetHoveredID(): void {
        this.setState({
            hoveredID: '',
        });
    }

    private renderEventSlide(pin: Pin, key: number): JSX.Element | null {
        if (!pin.location || !pin.location.name) {
            return null;
        }

        const startDate = dayjs(pin.startingAt).format('DD MMMM YYYY');
        const endDate = dayjs(pin.endingAt).format('DD MMMM YYYY');
        const date = startDate + (startDate !== endDate ? ` - ${endDate}` : '');
        const name = pin.location.name.toUpperCase();

        const onEventSlideClick = (): void => {
            if (pin.url) {
                window.open(pin.url, '_blank');
            }
        };

        return (
            <div key={key}>
                <button
                    onMouseEnter={(): void => this.setHoveredID(pin.location.code)}
                    onMouseLeave={this.unsetHoveredID}
                    onClick={onEventSlideClick}
                    className="arrow-box"
                >
                    <span>{date}</span>
                    <span>{name}</span>
                    <span>{pin.subTitle}</span>
                    <p>{pin.title}</p>
                    <FontAwesomeIcon aria-hidden="true" icon={faArrowRight} />
                </button>
            </div>
        );
    }

    public render(): JSX.Element {
        let selectedEvent;
        if (this.props.events !== undefined) {
            for (const localEvent of this.props.events) {
                if (localEvent.location !== undefined && localEvent.location.code === this.state.hoveredID) {
                    selectedEvent = localEvent;
                }
            }
        }

        let shouldBeInfinite = true;
        if (this.props.events !== undefined && this.props.events.length === 1) {
            shouldBeInfinite = false;
        }

        const settings: SliderSettings = {
            dots: false,
            speed: 300,
            infinite: shouldBeInfinite,
            vertical: true,
            draggable: false,
            slidesToShow: 2,
            slidesToScroll: 3,
            verticalSwiping: true,
            adaptiveHeight: false,
            nextArrow: <DownArrow t={this.props.t} />,
            prevArrow: <UpArrow t={this.props.t} />,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        draggable: true,
                        vertical: false,
                        verticalSwiping: false,
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        draggable: true,
                        vertical: false,
                        verticalSwiping: false,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        const events = sortBy(this.props.events, 'startingAt');

        return (
            <section className="InteractiveMap">
                <SentenceAnimation {...this.props} />
                <div className="InteractiveMapContainer">
                    <div className="column-right-bloc">
                        <div className="im-inner-div">
                            <EventsMap
                                highlightedEvent={selectedEvent}
                                hoveredID={this.state.hoveredID}
                                isPulsating={this.state.hoveredID !== null}
                                events={events}
                            />
                        </div>
                        <div className="im-slider-container">
                            <Slider {...settings}>{map(events, this.renderEventSlide)}</Slider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
