/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import { Link } from '../../../i18n';
import Slider, { Settings as SliderSettings } from 'react-slick';
import classnames from 'clsx';
import { useWindowWidth } from '../../../hooks';
import { WithT } from 'i18next';
import { HomeCycleLayer, HomeCycle } from '../../../domain/editorial';
import { StackedMoviePosters } from '../../common/StackedMoviePosters';
import AriaRole from '../../AriaRole';
import classNames from 'clsx';

interface ThematicCyclesProps extends WithT {
    cycleLayer?: HomeCycleLayer;
    language: 'fr' | 'en';
}

const sliderSettings: SliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1420,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

const ThematicCycles = ({ cycleLayer, language, t }: ThematicCyclesProps): JSX.Element | null => {
    const title: string = t('thematicCycles');
    useWindowWidth(); // use Window Size effect triggering re-renders on window resizes
    const ref: React.RefObject<HTMLAnchorElement> = useRef<HTMLAnchorElement>(null);
    const [indexHovered, setIndexHovered] = useState<number | null>(null);

    if (cycleLayer == null || cycleLayer.cycles == null || cycleLayer.cycles.length < 1) {
        return null;
    }

    return (
        <section className="ThematicCycles">
            <div className="ThematicCycles-Header">
                <h2 className={classNames('title', 'title-2')}>
                    <span className={classnames('first-letter', language)}>{title.charAt(0)}</span>
                    <span className={classnames('first-word', language)}>{title.slice(1).split(' ')[0]}</span>
                    <span className={classnames('second-word', language)}>{title.slice(1).split(' ')[1]}</span>
                    <span className="visually-hidden">{title}</span>
                </h2>
                <Link href="/cycles">
                    <a className={classNames('News-Header-SeeMore', 'link-dotted')} title="(Voir plus) de nouveautés">
                        {t('common:seeMore')}
                    </a>
                </Link>
            </div>
            <Slider {...sliderSettings} className="MaxWidthSection">
                {cycleLayer.cycles!.slice(0, 3).map(
                    (cycle: HomeCycle, i: number): JSX.Element => {
                        return (
                            <div key={i}>
                                <Link href={{ pathname: '/cycle', query: { id: cycle.id } }}>
                                    <a
                                        className="thumbnails-box"
                                        ref={ref}
                                        onMouseOver={(): void => setIndexHovered(i)}
                                        onFocus={(): void => setIndexHovered(i)}
                                        onMouseOut={(): void => setIndexHovered(null)}
                                        onBlur={(): void => setIndexHovered(null)}
                                        role={AriaRole.ARTICLE}
                                    >
                                        {cycle.works && (
                                            <StackedMoviePosters
                                                movies={cycle.works}
                                                width={160}
                                                height={213}
                                                spacing={10}
                                                limit={8}
                                                t={t}
                                                isHovering={i === indexHovered}
                                            />
                                        )}
                                        <h3 className={classNames('title', 'title-3')}>{cycle.title}</h3>
                                        <span>{cycle.subTitle}</span>
                                    </a>
                                </Link>
                            </div>
                        );
                    }
                )}
                {ref.current && ref.current.clientHeight && (
                    <div className="ThematicCycles-SeeMoreSlide">
                        <Link href="/">
                            <a
                                className="is-stroke-effect button"
                                style={{ height: `${ref.current && ref.current.clientHeight}px` }}
                            >
                                <span>{t('common:seeMore')}</span>
                            </a>
                        </Link>
                    </div>
                )}
            </Slider>
        </section>
    );
};

export default ThematicCycles;
